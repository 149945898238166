(function ($) {
  'use strict';

  Drupal.behaviors.whatsNewFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (!this.attached) {
        var $template = $('.js-whats-new-formatter--v1', context);

        $template.each(function () {
          var $this = $(this);
          var $carousel = $this.find('.js-whats-new-formatter__carousel');
          var arrowsDiv = $this.find('.js-whats-new-formatter__controls');

          // Only init carousel on desktop
          // Note: slick doesnt reinit when leaving the breakpoint. Here slick only gets destroyed when sizing down. Doesnt work when sizing up but that's a edge case not worth using $(window).resize()
          $carousel.not('.slick-initialized').slick({
            slidesToShow: 4,
            speed: 300,
            slidesToScroll: 1,
            infinite: true,
            appendArrows: arrowsDiv,
            responsive: [
              {
                breakpoint: 769,
                settings: 'unslick'
              }
            ]
          });
        });
      }

      this.attached = true;
    }
  };
})(jQuery);
